import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import ContactWidget from '../components/ContactWidget';
import ModalsFlow from '../components/Modals/ModalsFlow';
import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationBlog from '../assets/images/illustrations/Blog_banner.png';

const AboutPage = ({ data }) => (
  <>
    <SEO title="Our Blog" />
    <div className="common-page blog-list-page">
      <HeadingSection
        h1="Our Blog"
        h2="The latest updates, stories, ideas and guides from Sprive"
        image={imageIllustrationBlog}
      />

      <SecondSection postsData={data.allMarkdownRemark.edges} />
    </div>
  </>
);

const SecondSection = ({ postsData }) => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  // Filter out the ones which are scheduled for future
  const sortedPosts = postsData.filter((post) => new Date(post.node.frontmatter.date) < new Date())
  .sort((a, b) => new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date))

  return (
    <section className="second-section">
      <div className="container">
        <div className="row">
          {sortedPosts.map((post) => (
            <div className="col-md-6" key={post.node.id}>
              <Link to={post.node.fields.slug} className="blog-card">
                <div className="blog-card__image">
                  <GatsbyImage
                    sizes={{
                      ...post.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData,
                      aspectRatio: 19 / 10,
                    }}
                    image={post.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
                    alt={`Photo - ${post.node.frontmatter.title}`}
                    title={post.node.frontmatter.title}
                    imgStyle={{ objectFit: 'contain' }} />
                </div>
                <div className="blog-card__content">
                  <h4 className="title">{post.node.frontmatter.title}</h4>
                  <p className="caption">{post.node.frontmatter.description}…</p>
                  <div className="blog-card__content__bottom">
                    <span className="date">{post.node.frontmatter.date}</span>
                    <span className="category">{post.node.frontmatter.category}</span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
            <ContactWidget
              handleClick={() => setIsContactModalOpen(true)}
              title="Speak to a mortgage expert for FREE"
            />
            <ModalsFlow
              isFirstOpened={isContactModalOpen}
              setIsFirstOpened={(state) => setIsContactModalOpen(state)}
              firstModalIndex={1}
              usePureCalendlyWidget
            />
        </div>
      </div>
    </section>
  );};

export default AboutPage;

export const pageQuery = graphql`query BlogList {
  allMarkdownRemark(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {frontmatter: {templateKey: {eq: "blog-item"}}}
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          date
          category
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 570, height: 300, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}`;
